import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  UrlSegment,
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth-service/auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoad implements CanLoad {
  constructor(private _authServ: AuthService, private _router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this._authServ.isLoggedIn().pipe(
      take(1),
      map(isLoggedIn => {
        if (!isLoggedIn) {
          // redirect to login page
          this._router.navigateByUrl('/login', { replaceUrl: true });
        }
        return isLoggedIn;
      })
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthGuardAct implements CanActivate {
  constructor(private _authServ: AuthService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this._authServ.isLoggedIn().pipe(
      take(1),
      map(isLoggedIn => {
        if (!isLoggedIn) {
          // redirect to login page
          this._router.navigateByUrl('/login', { replaceUrl: true });
        }
        return isLoggedIn;
      })
    );
  }
}
