export const environment = {
  production: false
};

export const BASE_URL = 'https://dev.logger.mydiningbot.com';

export const API = {
  login: '/auth/login',
  appListUrl: '/api/applist',
  isSourceUrl: '/api/is-source'
};

export const ACCESS_TOKEN_KEY = 'access_token';
export const USER_INFO_KEY = 'user_info';
