import { Injectable, Optional, Inject, Provider } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { APP_BASE_HREF } from "@angular/common";

@Injectable()
export class OutRequestsInterceptor implements HttpInterceptor {
  originUrl = "";
  constructor(@Optional() @Inject(APP_BASE_HREF) origin: string) {
    if (origin !== null) {
      this.originUrl = origin;
    }
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const reqClone = request.clone({ url: `${this.originUrl}${request.url}` });
    return next.handle(reqClone);
  }
}

export const outRequestsInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: OutRequestsInterceptor,
  multi: true
};
