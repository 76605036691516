import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, of, ReplaySubject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { tap, switchMap, retry, catchError } from 'rxjs/operators';
import { API, ACCESS_TOKEN_KEY, USER_INFO_KEY } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from 'ngx-webstorage-service';
import { STORAGE_PROVIDER_TOKEN } from '../../providers';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn$: ReplaySubject<boolean> = new ReplaySubject(1);
  private _accessToken: string;
  private _user: User;

  get accessToken(): string {
    return this._accessToken;
  }

  get userInfo() {
    return this._user;
  }

  constructor(
    private _http: HttpClient,
    private _jwtHelper: JwtHelperService,
    @Inject(STORAGE_PROVIDER_TOKEN) private _storage: StorageService
  ) {
    this._checkToken();
  }

  public isLoggedIn(): Observable<boolean> {
    return this._isLoggedIn$.asObservable();
  }

  private async _checkToken() {
    const accessToken = await this._storage.get(ACCESS_TOKEN_KEY);
    if (accessToken) {
      const userInfo = await this._storage.get(USER_INFO_KEY);
      const isExpired = this._jwtHelper.isTokenExpired(accessToken);
      if (!isExpired) {
        userInfo ? (this._user = JSON.parse(userInfo)) : this.logout();
        // set token
        this._accessToken = accessToken;
        // notifiy the app of the auth state
        this._isLoggedIn$.next(true);
        return;
      }
    }
    this.logout();
  }

  login(credentialsForm: NgForm): Observable<any> {
    return this._http.post(API.login, credentialsForm).pipe(
      tap(
        (res: {
          user: {
            id: string;
            // email: user.email,
            firstName: string;
            lastName: string;
            image_url: string;
            // telephone: user.telephone,
            user_type: 'admin' | 'superAdmin';
          };
          token: string;
        }) => {
          if (res) {
            // set the jwt token and the user info
            this._storage.set(ACCESS_TOKEN_KEY, res.token);
            this._accessToken = res.token;
            // set user info
            this._user = {
              id: res.user.id,
              name: `${res.user.firstName} ${res.user.lastName}`,
              type: res.user.user_type,
              imageUrl: res.user.image_url
            };
            // save user info in db
            this._storage.set(USER_INFO_KEY, JSON.stringify(this._user));

            // notifiy the app of the auth state
            this._isLoggedIn$.next(true);

            // TODO: show a welcoming message
          }
        }
      )
    );
  }

  async logout() {
    console.log('logging out');
    this._isLoggedIn$.next(false);
    this._accessToken = null;
    // remove JWT Token
    await this._storage.remove(ACCESS_TOKEN_KEY);
    // remove user info
    await this._storage.remove(USER_INFO_KEY);
  }
}
