import { Injectable, Inject, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as sioc from 'socket.io-client'; // socket io for client side
import { APP_BASE_HREF } from '@angular/common';
import { BASE_URL } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private _socket: SocketIOClient.Socket;

  public get socket(): SocketIOClient.Socket {
    return this._socket;
  }

  constructor() {}

  /**
   * Connects socket.io to the server
   * @singleton Can be effectively called only once during the life time of the application
   * @param authToken can be retreived from the server when logged in or from a local store if it was saved in.
   */
  public connect(authToken: string): void {
    this._socket = sioc(BASE_URL, {
      autoConnect: true, // Unnecessary => Default is true
      query: {
        token: authToken
      },
      // TODO: try to remove the following to apply the default and check if it works with CORS
      transports: ['websocket', 'polling', 'flashsocket']
    });
  }
}
