import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardLoad, AuthGuardAct } from './guards/auth-guard/auth.guard';
import { NotAuthGuardLoad, NotAuthGuardAct } from './guards/not-auth-guard/not-auth.guard';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: 'logs',
    loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule),
    canLoad: [AuthGuardLoad],
    canActivate: [AuthGuardAct]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canLoad: [NotAuthGuardLoad],
    canActivate: [NotAuthGuardAct]
  },
  { path: '', redirectTo: 'logs', pathMatch: 'full' },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
