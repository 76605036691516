import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/* General Interceptor for out requests */
import { outRequestsInterceptorProvider } from './interceptors/out-requests-interceptor/out-requests.interceptor';
/* JWT (JSON Web Token) */
import { JwtModule, JWT_OPTIONS, JwtModuleOptions } from '@auth0/angular-jwt';
/* Storage */
import { LOCAL_STORAGE, SESSION_STORAGE } from 'ngx-webstorage-service';
import { STORAGE_PROVIDER_TOKEN } from './providers';
/* Authentication Interceptor */
import { authInterceptorProvider } from './interceptors/auth-interceptor/auth.interceptor';
/* Environment Variables */
import { ACCESS_TOKEN_KEY, BASE_URL } from '../environments/environment';
/* Shared Module */
import { SharedModule } from './shared/shared.module';

export function jwtOptionsFactory(storage: Storage) {
  return {
    tokenGetter: () => {
      return storage.get(ACCESS_TOKEN_KEY);
    },
    whitelistedDomains: [BASE_URL]
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [STORAGE_PROVIDER_TOKEN, Injector]
      }
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule
  ],
  providers: [
    // Notice we are using SESSION_STORAGE instead of LOCAL_STORAGE to enhance security
    { provide: STORAGE_PROVIDER_TOKEN, useExisting: SESSION_STORAGE },
    /* Interceptors */
    outRequestsInterceptorProvider,
    authInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
