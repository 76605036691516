import { Component } from '@angular/core';
import { AuthService } from './services/auth-service/auth.service';
import { Router } from '@angular/router';
import { SocketService } from './services/socket-service/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: 'mydiningbot-logger';
  constructor(private _authServ: AuthService, private _socketServ: SocketService, private _router: Router) {
    this._authServ.isLoggedIn().subscribe(isLoggedIn => {
      try {
        if (isLoggedIn) {
          // NOTICE: The Auth Service is going to get the token first through http;
          // then we use it to authorize socket connection
          this._socketServ.connect(this._authServ.accessToken);
          this._socketServ.socket.on('logout', () => {
            this._authServ.logout();
          });
        } else {
          // this._authServ.logout();
          // close the scoket (disconnect manually)
          console.log('closing socket');
          this._socketServ.socket && this._socketServ.socket.close();
          this._router.navigateByUrl('/login', { replaceUrl: true });
        }
      } catch (err) {
        // console.error(err);
      }
    });
  }
}
